//!\\ Scaffoldinng | Build //!\\
// .adminBar, .adminBarEdit {
//   display: none;
// }


@media (min-width: #{$nav-breakpoint + 1px}) {
  .menuMain {
    max-width: calc(#{$container-max-width} - #{$card-gap-width});
  }
}


// Desktop - Homepage overall layout
@media (min-width: #{$carousel-breakpoint + 1px}) {
  body.homepage .contentContainer.lifted-quickgiving {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .homeIntroWrapper, .homeFeaturesWrapper, .homeFeedsWrapper { 
      flex: unset;
      width: 100%;
    }

    .carousel {
      width: 100%;
      flex-basis: calc(((100% / 3) * 2) - #{$card-gap-width});
      margin: 0 #{$card-gap-width / 2};
      padding: 0;
      //padding-left: calc(#{$site-bleed} - #{$card-gap-width / 2});
      min-width: $card-min-width;
    }

    .homefeaturecategory-homeboxquickgiving {
      width: 100%;
      flex-basis: calc((100% / 3) - #{$card-gap-width});
      margin-top: 0;
      margin: 0 #{$card-gap-width / 2};
      padding: 0;
      padding-right: calc(#{$site-bleed} - #{$card-gap-width / 2});
      min-width: $card-min-width;

      .homepage-quickgiving--container {
        height: 100%;
        padding-top: #{$spacer * 1.5};
        padding-bottom: #{$spacer * 1.5};
        padding-left: #{$spacer * 1.5};
        padding-right: #{$spacer * 1.5};
        flex-direction: column;
        align-items: flex-start;
      }

      .selectDonationAmount {
        text-align: left;
      }

      .quickGivingPanel .formQuestion.donationAmount.donationAmountOther {
        margin-left: 0;
      }

      .quickGivingPanel .donateBtn {
        display: table;
        margin-left: 0;
        margin-top: $spacer;
      }
    }

  }
}


// Homepage quick giving - Overall layout
.homefeaturecategory-homeboxquickgiving {
  border-radius: $border-radius;
}


// Homepage quick giving - Heading layout
.homepage-quickgiving--copy {
  margin: 0 0 $spacer 0;
  margin-right: 0;
}

// Desktop - Carousel layout
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselImageMultiple .carouselDetailWrapper {
    padding-left: 0;
    padding-right: 0;
  }
}


// Carousel - Overall layout
.carousel {
  padding-left: #{$card-gap-width / 2};
  padding-right: #{$card-gap-width / 2};
}


// Desktop - Homepage stats layout
@media (min-width: #{$impact-stats-breakpoint + 1px}) {
  .homefeaturecategory-homeboximpactstats td,
  .homefeaturecategory-homeboximpactstats tr {
    width: auto !important;
  }
  .homefeaturecategory-homeboximpactstats tbody {
    align-items: flex-end;
  }
  .homefeaturecategory-homeboximpactstats td:first-child > * {
    margin-right: #{$spacer};
  }
}


// Homepage features - Overall layout
.homeFeatures {
  padding-left: 0;
  padding-right: 0;
}


// Overall - Homepage stats layout
.homefeaturecategory-homeboximpactstats .homeImpactWrapper {
  padding: 0;
  > h2 {
    margin-left: #{$card-gap-width / 2};
    margin-right: #{$card-gap-width / 2};
    border-bottom: $border-width solid $border-colour;
    padding-bottom: $spacer;
  }
  table {
    margin: 0 auto;
  }
}


// Homefeeds title - Format
.homeFeed .feedsTitle {
  padding-left: 0;
  padding-right: 0;
  margin-left: #{$card-gap-width / 2};
  margin-right: #{$card-gap-width / 2};
  border-bottom: $border-width solid $border-colour;
  padding-bottom: $feeds-title-margin-bottom;
}


// Desktop - Home feeds overall layout
@media (min-width: map-get($breakpoints, md) + 1px) {
  .homeFeedBox1 {
    flex: 2;
    margin-right: 0;
  }
}


// Footer layout
.pageFooterWrapper {
  width: calc(100% - #{$card-gap-width});
  max-width: $footer-max-width;
  background-color: transparent;
  margin: 0 auto $footer-margin-y;
}

.pageFooter {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background-color: $footer-background-colour;
  ul {
    margin-bottom: 1em;
  }
}

.footerBox[class*="ewsletter"] {
  border-radius: $border-radius;
  margin-bottom: $home-feeds-padding-bottom;
  input:focus {
    background-color: $white;
  }
}

// Desktop - Footer format
@media (min-width: map-get($breakpoints, md) + 1px) {
  // Newslettter - Layout
  .footerBox[class*="ewsletter"] {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    > h1, > h2, > h3, > h4, > h5, > h6 {
      flex: 1;
      padding: 0;
    }
    > *:not(h1, h2, h3, h4, h5, h6) {
      flex: 4;
      padding: 0;
    }
  }

  // Footer links - Format
  .pageFooter ul[class*='inks'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    li {
      opacity: 0.75;
      width: auto;
      margin-right: #{$spacer * 0.5};
      padding-left: #{$spacer * 0.5};

    }
  }
}


// Carousel Inner listing page
// div[class*='ListingPage'] .carousel