// Home features - Overall layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );

  // Home features - Card style
  .homeFeature {
    @include card (
      $card-details-padding: $spacer * 1.5,
      $card-details-background-colour: $grey-lightest,
      $card-hover-details-background-colour: $grey-lightest,
      $card-heading-margin-bottom: 0,
      $card-summary-enabled: false,
    );
    @include card-basic;
  }
}


// Home feeds -  Overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

// Home feed - Specific layout
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
  // Home feed - Specific card style
  .feedItem {
    @include card (
      $card-details-padding: $spacer 0
    );
    @include card-basic;
  }
}

// Home feed - Specific layout
.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  );
  // Home feed - Specific card style
  .feedItem {
    @include card (
      $card-image-on: false,
      $card-details-padding: 0,
      $card-summary-enabled: false
    );
    @include card-basic;
  }
}




//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}